import { randomString } from "../utils.js";
/** Convert a DBKeyMutationSet (which is an internal format capable of looking up changes per ID)
 * ...into a DBOperationsSet (which is more optimal for performing DB operations into DB (bulkAdd() etc))
 *
 * @param inSet
 * @returns DBOperationsSet representing inSet
 */
export function toDBOperationSet(inSet, txid = "") {
    // Fictive transaction:
    if (!txid)
        txid = randomString(16);
    // Convert data into a temporary map to collect mutations of same table and type
    const map = {};
    for (const [table, ops] of Object.entries(inSet)) {
        for (const [key, op] of Object.entries(ops)) {
            const mapEntry = map[table] || (map[table] = {});
            const ops = mapEntry[op.type] || (mapEntry[op.type] = []);
            ops.push(Object.assign({ key }, op)); // DBKeyMutation doesn't contain key, so we need to bring it in.
        }
    }
    // Start computing the resulting format:
    const result = [];
    for (const [table, ops] of Object.entries(map)) {
        const resultEntry = {
            table,
            muts: [],
        };
        for (const [optype, muts] of Object.entries(ops)) {
            switch (optype) {
                case "ups": {
                    const op = {
                        type: "upsert",
                        keys: muts.map(mut => mut.key),
                        values: muts.map(mut => mut.val),
                        txid
                    };
                    resultEntry.muts.push(op);
                    break;
                }
                case "upd": {
                    const op = {
                        type: "update",
                        keys: muts.map(mut => mut.key),
                        changeSpecs: muts.map(mut => mut.mod),
                        txid
                    };
                    resultEntry.muts.push(op);
                    break;
                }
                case "del": {
                    const op = {
                        type: "delete",
                        keys: muts.map(mut => mut.key),
                        txid,
                    };
                    resultEntry.muts.push(op);
                    break;
                }
            }
        }
        result.push(resultEntry);
    }
    return result;
}
