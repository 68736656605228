import { setByKeyPath } from '../utils.js';
export function subtractChanges(target, // Server change set
changesToSubtract // additional mutations on client during syncWithServer()
) {
    var _a, _b, _c;
    for (const [table, mutationSet] of Object.entries(changesToSubtract)) {
        for (const [key, mut] of Object.entries(mutationSet)) {
            switch (mut.type) {
                case 'ups':
                    {
                        const targetMut = (_a = target[table]) === null || _a === void 0 ? void 0 : _a[key];
                        if (targetMut) {
                            switch (targetMut.type) {
                                case 'ups':
                                    delete target[table][key];
                                    break;
                                case 'del':
                                    // Leave delete operation.
                                    // (Don't resurrect objects unintenionally (using tx(get, put) pattern locally))
                                    break;
                                case 'upd':
                                    delete target[table][key];
                                    break;
                            }
                        }
                    }
                    break;
                case 'del':
                    (_b = target[table]) === null || _b === void 0 ? true : delete _b[key];
                    break;
                case 'upd': {
                    const targetMut = (_c = target[table]) === null || _c === void 0 ? void 0 : _c[key];
                    if (targetMut) {
                        switch (targetMut.type) {
                            case 'ups':
                                // Adjust the server upsert with locally updated values.
                                for (const [propPath, value] of Object.entries(mut.mod)) {
                                    setByKeyPath(targetMut.val, propPath, value);
                                }
                                break;
                            case 'del':
                                // Leave delete.
                                break;
                            case 'upd':
                                // Remove the local update props from the server update mutation.
                                for (const propPath of Object.keys(mut.mod)) {
                                    delete targetMut.mod[propPath];
                                }
                                break;
                        }
                    }
                    break;
                }
            }
        }
    }
}
