const plugins = require('./utils/plugins');

function loadDemoData() {
    const posts = require('./model/posts');
    posts.loadDemo().then(function () {
        posts.renderPosts("#post-list").then(function () {
            console.info('Posts rendered');
        });
    });

    const badges = require('./model/badges');
    badges.loadDemo().then(function () {
        badges.renderBadges("#badges-list").then(function () {
            console.info('Badges rendered');
        });
    });

    const quests = require('./model/quests');
    quests.loadDemo().then(function () {
        quests.renderQuestsRelevant("#quests-relevant").then(
            function () {
                console.info('Quests relevant rendered');
        }); 
        quests.renderQuestsTable("#quests-table").then(
                function () {
                console.info('Quests table rendered');
        });
    });

    const events = require('./model/events');
    events.loadDemo().then(function () {
        events.renderEvents("#event-list").then(function () {
            console.info('Events rendered');
        });
    });

    const groups = require('./model/groups');
    groups.loadDemo().then(function () {
        groups.renderGroups("#group-list").then(function () {
            console.info('Groups rendered');
        });
    });

    const partners = require('./model/partners');
    partners.loadDemo().then(function () {
        partners.renderPartners("#partners-list").then(function () {
            console.info('Partners rendered');
        });
    });

}

function loadInitalComponents(){
    /*-----------------
        PAGE LOADER 
    -----------------*/
    require('./global/page-loader');
}

function clearAllSelectedMenus() {
    let menuItems = document.querySelectorAll(".active");
    menuItems.forEach(function (item) {
        // console.log("clearAllSelectedMenus", item);
        item.classList.remove('active');
    });
}

function markAllEquivalentMenus(hrefMenu) {
    let menuItems = document.querySelectorAll("a[href='" + hrefMenu + "']");
    // console.log("hrefMenu", menuItems);
    menuItems.forEach(function (item) {
        item.parentElement.classList.add('active');
    });
}

function registerMenuEvents() {
    let menuItems = document.querySelectorAll(".menu-item-link");
    menuItems.forEach(function (item) {
        item.addEventListener('click', function (e) {
            e.preventDefault();
            let menuItemClicked = e.target;
            //clear all selected menus
            clearAllSelectedMenus();
            //select all menus
            markAllEquivalentMenus(menuItemClicked.getAttribute('href'));
        });
    });
}

function loadVisualComponents() {
    /*----------------
        SCROLLBARS 
    ----------------*/
    require('simplebar');

    /*------------
        UTILS 
    ------------*/
    require('./utils/svg-loader');
    require('./utils/liquidify');
    require('./utils/demo-icons');

    /*---------------
    TOOLTIPS 
    ---------------*/
    require('./global/tooltips');

    /*---------------
        POPUPS 
    ---------------*/
    require('./global/popups');

    /*-----------------
        ACCORDIONS
    -----------------*/
    require('./global/accordions');

    /*------------
        FORM 
    ------------*/
    require('./form/form-rating');
    require('./form/form-counter');
    require('./form/form-switch');
    require('./form/form-input');
    require('./form/interactive-input');

    /*--------------
        LANDING 
    --------------*/
    require('./landing/landing.tabs');

    /*-------------
        HEADER 
    -------------*/
    require('./header/header.progressBar');
    require('./header/header.dropdown');

    /*-------------
        SIDEBAR 
    -------------*/
    require('./sidebar/sidebar');

    /*-----------------
        USER AVATAR 
    -----------------*/
    //require('./global/user-avatar.hexagons');

    /*----------
        MAPS 
    ----------*/
    // require('./global/maps');

    /*---------------
        CONTENT 
    ---------------*/
    require('./content/content.slider');
    require('./content/content.progressBar');
    require('./content/content.dropdown');
    require('./content/content.tab');

    /*-------------
        CHARTS
    -------------*/
    require('./global/charts');

    /*---------------
        WIDGETS 
    ---------------*/
    // require('./widget/twitter');

    /*---------------
        DATA TABLES
    ---------------*/
    require('./global/data-tables');

    
}

function registerServiceWorker() {
    navigator.serviceWorker.register(
        new URL('service-worker.js', import.meta.url),
        { type: 'module' }
    );
}

registerServiceWorker();
loadInitalComponents();
window.addEventListener("load", function () {
    /*---------------
        MULTILANGUAJE
    ---------------*/
    const i18ns = require('./utils/i18ns');
    registerMenuEvents();
    i18ns.loadTranslations().then(function () {
        /*---------------
            USER PROFILE
        ---------------*/
        const userProfile = require('./model/userProfile');        
        userProfile.init().then(function () {
            loadVisualComponents();
            loadDemoData();   
            i18ns.refreshTraslations();
        });

        
    });

});